export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Fast Food Simulator",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://app-192337.games.s3.yandex.net/192337/yhj8sl7ozw8r1wapes61mou57sujj692/index.html",
    domain: "fastfoodsimulator.org",
    gaId: "G-FSLVTYQX5S",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
